  #youtube-hero-container {
    width: 100%;
    height: 100%;
    background-color: #151515;
    background-image: url(../../assets/images/emlekszilank.png);
    background-repeat: no-repeat;
    background-position: center 30%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
  }

  .youtube-hero-container {
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 5%;
  }
  
  ol, ul {
    padding-left: 1rem;
  }

  .youtube-button:hover{
    background-color: #ff0000;
    color: #000000;
  }

  .youtube-button.stroke {
    background: transparent !important;
  }

  .youtube-button {
    display: inline-block;
    font-family: "montserrat-bold", sans-serif;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: .3rem;
    height: 5.4rem;
    line-height: 5.4rem;
    padding: 0 3rem;
    margin: 0 0.3rem 1.2rem 0;
    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    border: 5px solid #ff0000;
    line-height: 4.8rem;
  }

  .youtube-button-container {
    padding: 5%;
  }

  .youtube-hero-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
  }
  
  .youtube-video-and-title-container > p {
    color: #fff;
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
  }

  iframe {
    max-width: 100%;
  }

  .youtube-video-and-title-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 1024px) {
    #youtube-hero-container {
      background-image: url(../../assets/images/bg_red.png);
      background-attachment: scroll;
    }
  }

  @media screen and (max-width: 768px) {

    .youtube-button {
      display: inline-block;
      font-family: "montserrat-bold", sans-serif;
      font-size: 0.8rem;
      text-transform: uppercase;
      letter-spacing: .3rem;
      height: 4.8rem;
      line-height: 5rem;
      padding: 0 1rem;
      margin: 0 0.3rem 1.2rem 0;
      color: #FFFFFF;
      text-decoration: none;
      cursor: pointer;
      text-align: center;
      white-space: nowrap;
      border: 4px solid #ff0000;
      line-height: 4.4rem;
    }

    .youtube-video-and-title-container > p {
      font-size: 20px;
    }

    .youtube-hero-container {
      padding-left: 5%;
      padding-right: 5%;
    }
  }

  @media screen and (max-width: 600px) {
    .youtube-hero-container {
      padding-top: 15%;
      padding-left: 5%;
      padding-right: 5%;
    }
  }

  @media screen and (max-width: 400px) {
    .youtube-hero-container {
      padding-top: 15%;
      padding-left: 5%;
      padding-right: 5%;
    }
  }

  @media screen and (max-width: 375px) {
    .youtube-hero-container {
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 15%;
    }
  }

  @media screen and (max-width: 320px) {
    .youtube-hero-container {
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 15%;
    }
  }
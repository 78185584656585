  .contact_section_container  {
    background-image: url('../../assets/images/contact.jpg');
    background-position: top;
    background-size: cover;
    color: #fff;
    height: 100%;
    width: 100%;
  }
  
  .contact_container {
    flex-direction: column;
    display: flex;
    align-items: center;
    height: 500px;
    width: 800px;
    margin: auto;
  }

  .subscription_container {
    margin-top: 50px;
    width: 400px;
  }
  
  .contact_container > h1 {
    text-align: center;
    color: white;
    font-size: 50px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    margin-top: 60px;
    letter-spacing: -.1rem;
  }

  .subscription_container > h2 {
    text-align: center;
    color: white;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    margin-top: 10px;
  }
  
  .contact_container > p {
      text-align: center;
      color: gray;
      font-size: 32px;
      margin-top: 40px;
      font-family: 'Montserrat', sans-serif;
      letter-spacing: .2rem;
  }

  @media only screen and (max-width: 860px){
    .contact_container {
      height: 400px;
      width: 100%;
    }

    .contact_container > h1 {
      margin-top: 50px;
      font-size: 30px;
      letter-spacing: -.07rem;
    }

    .contact_container > p {
      font-size: 24px;
      letter-spacing: -.07rem;
    }
  }

  @media screen and (max-width: 767px) {
    .subscription_container > h2 {
      font-size: 15px;
    }

    .subscription_container {
      width: 100%;
    }
  }
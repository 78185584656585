.concert_page_container  {
    background-image: url('../../assets/images/event_background_blured.jpg');
    background-position: top;
    background-size: cover;
    color: #fff;
    height: 100%;
    width: 100%;
  }
  
  .concerts_container {
    flex-direction: column;
    display: flex;
    height: 300px;
    width: 100%;
    margin: auto;
  }

  .concerts_container > h1 {
    background-image: url('../../assets/images/bg_red.png');
    background-position: top;
    background-size: cover;
    text-align: center;
    color: white;
    font-size: 50px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    margin-top: 60px;
    letter-spacing: -.1rem;
    width: 90%;
    margin: auto;
  }
  
  .concert-soon-container > a {
    text-align: center;
    color: white;
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    letter-spacing: -.1rem;
    margin-bottom: 0;
  }

  .concert-soon-container{
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 0;
  }

  .tickets-container{
    width: 100%;
    margin-bottom: 0;
  }

  .tickets-container > a {
    text-align: center;
    color: white;
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    letter-spacing: -.1rem;
    margin-bottom: 0;
  }

  .concert-event-container {
    background-image: url('../../assets/images/concert.jpg');
    background-position: top;
    background-size: cover;
    display: flex;
    flex-direction: row;
    padding: 16px 10px;
    width: 90%;
    margin: auto;
    justify-content: center;
  }

  .widget-left{
    background-image: url('../../assets/images/bg_red.png');
    background-position: top;
    background-size: cover;
    text-decoration: none;
    display: block;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;
    margin-left: 0;
  }

  .widget-right{
    background-image: url('../../assets/images/bg_red.png');
    background-position: top;
    background-size: cover;
    text-decoration: none;
    display: block;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;
  }

  .concert-soon-container-root{
    margin-left: 10px;
    width: 300px;
    margin-right: 30%;
  }

  .ticket-container-root{
    margin-right: 10px;
    width: 300px;
  }

  @media only screen and (max-width: 860px){

    .concerts_container > h1 {
      margin-top: 50px;
      font-size: 30px;
      letter-spacing: -.07rem;
    }

    .concert-soon-container > a {
      font-size: 20px;
      letter-spacing: -.07rem;
    }

    .tickets-container > a {
      font-size: 20px;
      letter-spacing: -.07rem;
    }
  }

  @media only screen and (max-width: 375px){
    .concert-soon-container{
      width: 80%;
    }
  }
  .footer_section_container  {
    background-color: rgb(0, 0, 0);
    background-position: top;
    background-size: cover;
    color: #fff;
    height: 100%;
    width: 100%;
  }
  
  .footer_container {
    flex-direction: column;
    display: flex;
    align-items: center;
    height: 140px;
    width: 800px;
    margin: auto;
  }

  .footer-media-sites-icon {
    margin: 20px;
  }

  .footer-nav-item {
    margin-top: 30px;
    height: 20px;
  }
  
  .footer_container > p {
      text-align: center;
      color: rgba(255, 255, 255, 0.589);
      font-size: 12px;
      margin-top: 40px;
      font-family: 'Montserrat', sans-serif;
      letter-spacing: .2rem;
      font-weight: 900;
  }
  
  @media only screen and (max-width: 860px){
    .footer_container {
      width: 100%;
    }

    .footer_container > p {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 320px) {
    .footer-media-sites-icon {
      margin: 15px;
    }
  }
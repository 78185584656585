  .card_page_container  {
      background-image: url('../../assets/images/kreon_background_2.jpg');
      background-position: center;
      background-size: cover;
      color: #fff;
    }

  .card-icon {
    margin: 9px;
  }

  .cards {
    padding: 4rem;
    background: #fff;
  }
  
  h1 {
    text-align: center;
  }

  .cards__items {
    padding-left: 0;
  }

  .cards__container {
    display: flex;
    flex-flow: column;
    max-width: 1100px;
    width: 1100px;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 60px 0 45px;
  }
  
  .cards__item {
    display: flex;
    flex: 1;
    margin: 0 3rem;
    border-radius: 10px;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgb(0, 0, 0);
    -webkit-filter: drop-shadow(0 6px 20px rgb(0, 0, 0));
    filter: drop-shadow(0 6px 20px rgb(0, 0, 0));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__info {
    padding: 30px 30px 30px;
  }
  
  .cards__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1075px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1075px) {
    .cards__item {
      margin-bottom: 2rem;
    }

    .cards__container {
      display: flex;
      flex-flow: column;
      max-width: 1100px;
      width: 360px;
      margin: 0 auto;
    }
  }

  @media only screen and (max-width: 320px) {
    .cards__container {
      width: 100%;
    }

    .cards__item__info {
      padding: 20px!important;
    }

    .cards__item {
      margin: 0 2rem;
      margin-bottom: 2rem;
    }
  }

  @media only screen and (max-width: 300px) {
    .cards__item {
      margin: 0 1rem;
      margin-bottom: 2rem;
    }
  }

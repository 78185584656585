  #hero-container {
    width: 100%;
    height: 100%;
    background-color: #151515;
    background-image: url(../../assets/images/emlekszilank.png);
    background-repeat: no-repeat;
    background-position: center 30%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    min-height: 600px;
    position: relative;
  }

  .video-container {
    width: 800px;
    padding: 5%;
    padding-top: 1%;
    z-index: 500;
  }

  .video-settings {
    width: 100%;
    position: relative;
    padding: 0;
    padding-top: 56.2%;
  }

  .button:hover{
    background-color: #ff0000;
    color: #000000;
  }

  .button.stroke {
    background: transparent !important;
  }

  .button {
    display: inline-block;
    font-family: "montserrat-bold", sans-serif;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: .3rem;
    height: 5.4rem;
    line-height: 5.4rem;
    padding: 0 3rem;
    margin: 0 0.3rem 1.2rem 0;
    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    border: 5px solid #ff0000;
    line-height: 4.8rem;
  }

  .logo-image {
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 525px;
    width: 25%;
    object-fit: contain;
  }

  .hero-container {
    width: 100%;
    height: 95vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .video-and-title-container > p {
    color: #fff;
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
  }

  .video-and-title-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
  }

  @media screen and (max-width: 1540px) { 
    .logo-image {
      margin-top: 200px;
      width: 25%;
    }
  } 
  
  @media screen and (max-width: 1024px) {  
    .logo-image {
      margin-top: 200px;
      width: 40%;
    }

    .video-container {
      width: 800px;
    }

    #hero-container {
      background-image: url(../../assets/images/emlekszilank_phone.jpg);
      background-attachment: scroll;
    }
  }

  @media screen and (max-width: 900px) {
    .video-container {
      width: 720px;
    }
  }

  @media screen and (max-width: 768px) {
    .logo-image {
      margin-top: 150px;
      width: 70%;
    }

    .button {
      font-size: 0.8rem;
      height: 4.8rem;
      padding: 0 1rem;
      line-height: 4.4rem;
    }

    .video-and-title-container > p {
      font-size: 20px;
    }

    .video-container {
      width: 600px;
    }
  }

  @media screen and (max-width: 600px) {
    .video-container {
      width: inherit;
    }

    #hero-container {
      min-height: 585px;
    }
  }

  @media screen and (max-width: 400px) {
    #hero-container {
      min-height: 585px;
    }
  }

  @media screen and (max-width: 375px) {

  }

  @media screen and (max-width: 320px) {
    #hero-container {
      min-height: 500px;
    }
  }
  #aszf-hero-container {
    width: 100%;
    height: 100%;
    background-color: #151515;
    background-image: url(../../assets/images/emlekszilank.png);
    background-repeat: no-repeat;
    background-position: center 30%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
  }

  .aszf-button:hover{
    background-color: #ff0000;
    color: #000000;
  }

  .aszf-button.stroke {
    background: transparent !important;
  }

  .aszf-button {
    display: inline-block;
    font-family: "montserrat-bold", sans-serif;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: .3rem;
    height: 5.4rem;
    line-height: 5.4rem;
    padding: 0 3rem;
    margin: 0 0.3rem 1.2rem 0;
    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    border: 5px solid #ff0000;
    line-height: 4.8rem;
  }

  .aszf-logo-image {
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 525px;
    width: 25%;
    object-fit: contain;
  }

  .aszf-hero-container {
    width: 100%;
    height: 95vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .aszf-video-and-title-container > p {
    color: #fff;
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
  }

  .aszf-video-and-title-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
  }

  @media screen and (max-width: 1540px) { 
    .aszf-logo-image {
      margin-top: 200px;
      width: 25%;
    }
  } 
  
  @media screen and (max-width: 1024px) {  

    .aszf-logo-image {
      margin-top: 100px;
      width: 40%;
    }

    .aszf-video-container {
      width: 800px;
    }
  }

  @media screen and (max-width: 900px) {
    .aszf-video-container {
      width: 720px;
    }
  }

  @media screen and (max-width: 768px) {
    .aszf-logo-image {
      margin-top: 40px;
      width: 70%;
    }

    .aszf-button {
      font-size: 0.8rem;
      height: 4.8rem;
      padding: 0 1rem;
      line-height: 4.4rem;
    }

    .aszf-video-and-title-container > p {
      font-size: 20px;
    }

    .aszf-video-container {
      width: 600px;
    }

    #aszf-hero-container {
      background-image: url(../../assets/images/emlekszilank_phone.jpg);
    }
  }